<template>
    <div>
        <b-card no-body class="mb-2 p-1">
            <div class="row">
                <b-col cols="3">
                    <router-link
                        :to="{ name: 'DocumentCreate' }"
                        class="btn btn-success"
                    >
                        <b-icon-plus scale="1.2"/>
                    </router-link>
                </b-col>
                <b-col cols="9" class="text-right">
                    <b-button variant="primary" @click="applyFilters" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </b-col>
            </div>

            <b-collapse id="filters">
                <b-card-body>
                    <b-row>
                        <b-col md="3">
                            <b-form-group label="Тип">
                                <document-type-select v-model="filters.documentTypeId" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Дата от">
                                <b-form-datepicker
                                    v-model="filters.dateFrom"
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder=""
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Дата до">
                                <b-form-datepicker
                                    v-model="filters.dateTo"
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder=""
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Сотрудник">
                                <user-input v-model="filters.user" clear-button/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="row">
                        <b-col md="3">
                            <b-form-group>
                                <b-checkbox v-model="filters.hasAmount" switch>Введена сумма</b-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <b-checkbox v-model="filters.hasPayAt" switch>Установлена дата выплаты</b-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <b-checkbox v-model="filters.only_trashed" switch>Удаленные</b-checkbox>
                            </b-form-group>
                        </b-col>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-table
            responsive
            head-variant="dark"
            foot-clone
            :items="items"
            :fields="fields"
            @row-dblclicked="toForm"
            tbody-tr-class="pointer"
        >
            <template #cell(actions)="row">
                <b-button-group>
                    <b-button size="sm" variant="warning" @click="toForm(row.item)">
                        <b-icon-pencil-square/>
                    </b-button>
                    <b-button
                        v-show="!row.item.deleted_at"
                        @click="onDestroy(row.item.id)"
                        size="sm"
                        variant="danger"
                    >
                        <b-icon-trash-fill/>
                    </b-button>
                    <b-button
                        v-show="row.item.deleted_at"
                        @click="onRestore(row.item.id)"
                        size="sm"
                        variant="light"
                    >
                        <b-icon-arrow-return-left/>
                    </b-button>
                </b-button-group>
            </template>

            <template #foot(documentType.name)>
                Сумма:
            </template>

            <template #foot(amount)="data">
                {{ parseFloat(items.reduce((total, item) => total + item[data.field.key], 0).toFixed(2)) }}
            </template>
        </b-table>

        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from 'moment'
import UserInput from '@components/_common/UserInput';
import lsfMixin from '@utils/localStorageFilterMixin'
import {DocumentService} from '@services';
import DocumentTypeSelect from './components/DocumentTypeSelect.vue';

export default {
    name: 'Index',
    mixins: [lsfMixin],
    components: {
        UserInput,
        DocumentTypeSelect,
    },
    data() {
        return {
            items: [],
            fields: [
                {key: 'id', label: '№'},
                {key: 'type.name', label: 'Тип'},
                {key: 'user.fullname', label: "Сотрудник"},
                {
                    key: 'user.current_work_position.name',
                    label: 'Должность',
                },
                {
                    key: "start_at",
                    label: "Период с",
                    formatter(start_at) {
                        return moment(start_at).format('DD.MM.YYYY')
                    }
                },
                {
                    key: "end_at",
                    label: "Период по",
                    formatter(end_at) {
                        return moment(end_at).format('DD.MM.YYYY')
                    }
                },
                {key: "amount", label: "К выплате, руб"},
                {
                    key: 'pay_at',
                    label: "Дата выплаты",
                    formatter(pay_at) {
                        if (pay_at) {
                            return moment(pay_at).format('DD.MM.YYYY')
                        }
                        return 'Не установлена'
                    }
                },
                {key: "comment", label: "Комментарий"},
                {key: "actions", label: "Действия"},
            ],
            meta: {
                total_rows: 1,
                current_page: 1,
                per_page: 25,
            },
            filters: {
                documentTypeId: null,
                dateTo: null,
                dateFrom: null,
                user: {id: null},
                hasAmount: false,
                hasPayAt: false,
                only_trashed: false,
            },
        };
    },
    watch: {
        filters: {
            deep: true,
            handler(value) {
                this.watchFilters(value)
            },
        },
    },
    methods: {
        toForm(item) {
            this.$router.push({name: 'DocumentUpdate', params: {id: item.id}});
        },
        applyFilters() {
            const params = {
                with: ['type', 'user.current_work_position'],
                filters: this.filters,
                orders: {
                    id: 'desc',
                },
                limit: this.meta.per_page,
                page: this.meta.current_page,
            };

            DocumentService.getWithPagination(params).then((response) => {
                this.items = response.data.data;
                this.meta = {
                    total_rows: response.data.meta.total,
                    current_page: response.data.meta.current_page,
                    per_page: response.data.meta.per_page,
                };
            })
        },
        onDestroy(id) {
            this.$bvModal.msgBoxConfirm(
                'Вы действительно хотите удалить документ?',
                {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }
            )
                .then((value) => {
                    if (value) {
                        DocumentService.destroy(id)
                            .then(() => {
                                this.applyFilters();
                                this.$bvToast.toast('Удаление документа выполнено успешно', {variant: 'success'});
                            }).catch((response) => {
                                this.$bvToast.toast(response.data.message, {variant: 'danger'});
                            });
                    }
                });
        },
        onRestore(id) {
            this.$bvModal.msgBoxConfirm(
                'Вы действительно хотите восстановить документ?',
                {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }
            )
                .then((value) => {
                    if (value) {
                        DocumentService.restore(id)
                            .then(() => {
                                this.applyFilters();
                                this.$bvToast.toast('Восстановление документа выполнено успешно', {variant: 'success'});
                            }).catch(() => {
                                this.$bvToast.toast('Не удалось восстановить документ', {variant: 'danger'});
                            });
                    }
                });
        },
    },
    mounted() {
        this.initFilters(this, this.filters);
    },
}
</script>

<style>
.pointer {
    cursor: pointer;
}
</style>
