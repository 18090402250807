<template>
    <b-form-select v-model="model" :options="options">
        <template #first>
            <b-form-select-option :value="null"></b-form-select-option>
        </template>
    </b-form-select>
</template>

<script>
import {DOCUMENT_TYPES_ENDPOINT} from '@constants';

export default {
    name: 'DocumentTypeSelect',
    props: {
        value: {
            type: Number,

        },
    },
    data() {
        return {
            options: [],
        };
    },
    computed: {
        model: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        load() {
            this.$http.get(DOCUMENT_TYPES_ENDPOINT).then((response) => {
                this.options = response.data.map((option) => {
                    return {
                        value: option.id,
                        text: option.name,
                    }
                });
            });
        },
    },
    mounted() {
        this.load();
    }
}
</script>
